import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Switch from "react-switch";
import store from '../../store';
import api from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faSearch, faPrint, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from "react-router-dom";
import Erros from '../../components/Erro';

function NovoPedido() {
    let history = useHistory();
    let { id } = useParams();
    const { register, handleSubmit, errors } = useForm();
    const [fornecedores, setFornecedores] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [parceiros, setParceiros] = useState([]);
    const [compradores, setCompradores] = useState([]);
    const [materiais, setMateriais] = useState([]);
    const [pedido, setPedido] = useState([]);
    const [pedidoItem, setPedidoItem] = useState({});
    const [percentualDefault, setPercentualDefault] = useState({});
    const [lojas, setLojas] = useState([]);
    const [faturado, setFaturado] = useState(false);
    const [statusPedidos, setStatusPedidos] = useState();

    /*Constantes imutaveis*/
    const [grades] = useState([
        { tipo: "A", valoresGrade: "RN|P|M|G|GG|G1|G2|G3|G4|G5|G6", label: "A: RN, P, M, G, GG, G1, G2, G3, G4, G5 e G6" },
        { tipo: "B", valoresGrade: "1|2|3|4|5|6|7|8|10|12|14|16|18", label: "B: 1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 14, 16 e 18" },
        { tipo: "C", valoresGrade: "34|36|38|40|42|44|46|48|50|52|54|56|58", label: "C: 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56 e 58" },
        { tipo: "D", valoresGrade: "16|17|18|19|20|21|22|23|24|25|26|27", label: "D: 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26 e 27" },
        { tipo: "E", valoresGrade: "23|24|25|26|27|28|29|30|31|32|33|34|35|36", label: "F: 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35 e 36" }
    ]);

    useEffect(() => {
        async function fetchData() {
            if (id) {
                await api.get(`/Pedido/id/${id}`).then(response => {
                    carregarLojasCompradores(response.data.clienteId);
                    setPedido(response ? response.data : null);
                });
            }
            await api.get('/Pedido/listaStatus').then(response => {
                setStatusPedidos(response ? response.data : null);
            });
            await api.get('/Fornecedor/todos/').then(response => {
                setFornecedores(response ? response.data : null);
            });
            await api.get('/Cliente/todos').then(response => {
                setClientes(response ? response.data : null);
            });
            await api.get('/Material/todos').then(response => {
                setMateriais(response ? response.data : null);
            });
            await api.get('/Parceiro/todos').then(response => {
                setParceiros(response ? response.data : null);
            });
            await api.get('/Pedido/percentualDefault').then(response => {
                setPercentualDefault(response ? response.data : null);
            });
        }
        fetchData();
    }, [id]);

    const successMenssage = () => {
        const { dispatch } = store;
        dispatch({
            type: 'ADD_ERRO_MENSAGEM',
            error: {
                tipo: 'alert-success',
                hideMessage: true,
                errorMessage: [{ mensagem: 'Registro Salvo com sucesso!' }]
            }
        });
    }

    const onSubmitPedido = async data => {
        data.statusPedido = parseInt(data.statusPedido);
        data.dataFaturamento = data.dataFaturamento === '' ? null : data.dataFaturamento;
        data.compradorId = data.compradorId === '' ? null : data.compradorId;
        data.parceiroId = data.parceiroId === '' ? null : data.parceiroId;
        data.percentualParceiro = data.percentualParceiro === '' ? null : parseFloat(data.percentualParceiro.replace(',', '.'));
        data.percentualVerba = data.percentualVerba === '' ? 0 : parseFloat(data.percentualVerba.replace(',', '.'));
        data.percentualDesconto = data.percentualDesconto === '' ? 0 : parseFloat(data.percentualDesconto.replace(',', '.'));
        data.formaDePagamento = parseInt(data.formaDePagamento);
        if (!pedido.id) {
            await api.post('/Pedido', data).then(response => {
                if (response) {
                    setPedido(response.data);
                    history.push(`/pedidos/${response.data.id}`)
                    successMenssage();
                }
            });
        } else {
            data.id = pedido.id;
            await api.put('/Pedido', data).then(response => {
                if (response) {
                    setPedido(response.data);
                    successMenssage();
                }
            });
        }
    };

    const onSubmitPedidoItem = async data => {
        data.pedidoItem.percentual = parseFloat(data.pedidoItem.percentual.replace(',', '.'));
        data.pedidoItem.custoLiquido = parseFloat(data.pedidoItem.custoLiquido.replace('.', '').replace(',', '.'));
        data.pedidoItem.pedidoItemLojas.forEach(item => {
            item.tipo = parseInt(item.tipo);
        })
        if (!pedidoItem.produto) {
            alert('Selecione um produto!');
            return;
        }
        var arrayValoresGrade = grades.filter(f => f.tipo === data.pedidoItem.tipoGrade)[0].valoresGrade.split('|');
        arrayValoresGrade.map((item, index) => {
            var separador = index < arrayValoresGrade.length - 1 ? '|' : '';
            var prefixo = index > 0 ? data.pedidoItem.valoresGrade : '';
            return data.pedidoItem.valoresGrade = `${prefixo}${item}=${data.pedidoItem.valoresGradeTemp[index].valor}${separador}`;
        });
        data.pedidoItem.valoresGradeTemp = undefined;


        if (!data.pedidoItem.id) {
            data.pedidoItem.id = undefined;
            data.pedidoItem.pedidoItemLojas.map(item => {
                item.pedidoItemId = undefined;
                return item.quantidade = parseInt(item.quantidade);
            });
            await api.post('/Pedido/pedidoItem', data.pedidoItem).then(response => {
                if (response) {
                    setPedido(response.data);
                }
            });
        } else {
            data.pedidoItem.pedidoItemLojas.map(item => {
                return item.quantidade = parseInt(item.quantidade);
            });
            await api.put('/Pedido/pedidoItem', data.pedidoItem).then(response => {
                if (response) {
                    setPedido(response.data);
                }
            });
        }
        document.getElementById("btClose").click();
    };

    const editarPedidoItem = async data => {
        await api.get(`/Pedido/pedidoItem/${data}`).then(response => {
            if (response) {
                setPedidoItem(response.data);
                popularModelItem(response.data);
                handleTotal();
            }
        });
    }

    const gerarCommissao = async e => {
        e.preventDefault();
        await api.post(`/Lancamento/gerarLacamentoPedido/${pedido.id}`).then(response => {
            if (response) {
                history.push(`/Financeiro/lancamento/${response.data.pedidoId}`);
            }
        });
    }

    const popularModelItem = data => {

        if (data.id) {
            document.getElementById('pedidoItem.produto.descricao').value = data.produto.descricao;
            document.getElementById('pedidoItem.produto.referencia').value = data.produto.referencia;
            document.getElementById('pedidoItem.cor').value = data.cor;
            document.getElementById('pedidoItem.materialId').value = data.materialId;
            document.getElementById('pedidoItem.percentual').value = data.percentual.toLocaleString('pt-br');
            document.getElementById('pedidoItem.custoLiquido').value = data.custoLiquido.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' });

            lojas && lojas.map((item, index) => {
                var valorQuantidadeVendido = data.pedidoItemLojas?.filter(f => f.lojaId === item.id && f.tipo === 0)[0]?.quantidade;
                document.getElementById(`pedidoItem.pedidoItemLojas[${index}].quantidade`).value = valorQuantidadeVendido === undefined ? 0 : valorQuantidadeVendido;

                var valorQuantidadeFaturado = data.pedidoItemLojas?.filter(f => f.lojaId === item.id && f.tipo === 1)[0]?.quantidade;
                return document.getElementById(`pedidoItem.pedidoItemLojas[${index + lojas.length}].quantidade`).value = valorQuantidadeFaturado === undefined ? 0 : valorQuantidadeFaturado;

            });

            var arrayValoresGrade = data.valoresGrade.split('|');
            data.tipoGrade && grades.filter(f => f.tipo === data.tipoGrade)[0].valoresGrade.split('|').map((item, index) => (
                document.getElementById(`pedidoItem.valoresGradeTemp[${index}].valor`).value = arrayValoresGrade[index].split('=')[1]
            ));
        } else {
            setPedidoItem({});
            document.getElementById("formPedidoItem").reset();
            document.getElementById('pedidoItem.percentual').value = percentualDefault;
        }
    }

    function handleTotal() {
        var totalVenda = 0;
        var totalFaturado = 0;

        lojas && lojas.map((item, index) => {
            var valor = document.getElementById(`pedidoItem.pedidoItemLojas[${index}].quantidade`).value;
            totalVenda += valor ? parseInt(valor) : 0;

            valor = document.getElementById(`pedidoItem.pedidoItemLojas[${index + lojas.length}].quantidade`).value;
            totalFaturado += valor ? parseInt(valor) : 0;
            return 0;
        });
        document.getElementById('totalVenda').innerHTML = totalVenda;
        document.getElementById('totalFaturado').innerHTML = totalFaturado;
    }

    function handleInputChangePedido(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'clienteId') {
            carregarLojasCompradores(value);
        }
        if (name === 'parceiroId' && value) {
            document.getElementById('percentualParceiro').value = parceiros ? parceiros.filter(f => f.id === value)[0].percentalPadrao : '';
        }
        setPedido(prev => ({ ...prev, [name]: value }));
    }

    const carregarLojasCompradores = async value => {
        await api.get(`/Loja/${value}/todos`).then(response => {
            setLojas(response ? response.data : null);
        });

        await api.get(`/Comprador/${value}/todos`).then(response => {
            setCompradores(response ? response.data : null);
        });
    }

    function handleInputChangeFaturado(checked) {
        setFaturado(checked);
    }

    function handleInputChangePedidoItem(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name.substring(11, 100);
        setPedidoItem(prev => ({ ...prev, [name]: value }));
    }

    function handleInputChangeReferencia(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setPedidoItem(prev => ({ ...prev, produto: { referencia: value } }));
    }

    const localizarProduto = async e => {
        e.preventDefault();
        if (pedidoItem.produto?.referencia) {
            await api.get(`/Produto/${pedido.fornecedorId}/referencia/${encodeURIComponent(pedidoItem.produto.referencia)}`).then(response => {
                if (response.data) {
                    document.getElementById('pedidoItem.produto.descricao').value = response.data.descricao;
                    setPedidoItem(prev => ({ ...prev, produto: response.data, produtoId: response.data.id }));
                } else {
                    document.getElementById('pedidoItem.produto.referencia').value = "";
                    document.getElementById('pedidoItem.produto.descricao').value = "Produto não localizado!";

                }
            });
        }
    }

    const keyPressReferencia = (e) => {
        if (e.charCode === 13) {
            e.preventDefault();
            localizarProduto(e);
        }
    }

    const handleExcluir = async id => {
        if (window.confirm('Dejesa realmente excluir?')) {
            await api.delete(`/Pedido/pedidoItem/${id}`).then(response => {
                if (response) {
                    setPedido(response.data);
                }
            });
        }
    }

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div id="accordion">
                <div className="card">
                    <div className="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {!pedido.numero ? 'Novo Pedido' : `Pedido Nº ${pedido.numero}`}
                    </div>
                    <Erros />
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                            <div>
                                <form onSubmit={handleSubmit(onSubmitPedido)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>Fornecedor</label>
                                            <select id="fornecedorId" name="fornecedorId" value={pedido.fornecedorId} onChange={handleInputChangePedido} ref={register()} className="form-control" required>
                                                <option value=""></option>
                                                {
                                                    fornecedores && fornecedores.map((fornecedor, index) => (
                                                        <option key={index} value={fornecedor.id}>{fornecedor.razaoSocial}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.fornecedorId && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Cliente</label>
                                            <input type="hidden" name={pedido.pedidoItens?.length > 0 ? 'clienteId' : 'clienteId2'} defaultValue={pedido.clienteId} ref={register()} id="clienteId" disabled={pedido.pedidoItens?.length === 0} />
                                            <select id="clienteId" name={pedido.pedidoItens?.length > 0 ? 'clienteId2' : 'clienteId'} value={pedido.clienteId} onChange={handleInputChangePedido} ref={register()} className="form-control" required disabled={pedido.pedidoItens?.length > 0}>
                                                <option value=""></option>
                                                {
                                                    clientes && clientes.map((cliente, index) => (
                                                        <option key={index} value={cliente.id}>{cliente.nome}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.clienteId && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Comprador</label>
                                            <select id="compradorId" name="compradorId" value={pedido.compradorId} onChange={handleInputChangePedido} ref={register()} className="form-control">
                                                <option value=""></option>
                                                {
                                                    compradores && compradores.map((comprador, index) => (
                                                        <option key={index} value={comprador.id}>{comprador.nome}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-2">
                                            <label>Status</label>
                                            {pedido.status}
                                            <select id="statusPedido" name="statusPedido" value={pedido.statusPedido} ref={register()} onChange={handleInputChangePedido} className="form-control" required>
                                                <option value=""></option>
                                                {
                                                    statusPedidos && statusPedidos.map((status, index) => (
                                                        <option key={index} value={status.value}>{status.label}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.statusPedido && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label>Data Compra</label>
                                            <input type="date" name="dataCompra" defaultValue={pedido.dataCompra} className="form-control" ref={register()} id="dataCompra" placeholder="Data Compra" required />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Data Previsão Faturamento</label>
                                            <input type="date" name="dataPrevisaoFaturamento" defaultValue={pedido.dataPrevisaoFaturamento} className="form-control" ref={register()} id="dataPrevisaoFaturamento" placeholder="Data Previsão Faturamento" required />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label>Data Faturamento</label>
                                            <input type="date" name="dataFaturamento" defaultValue={pedido.dataFaturamento} className="form-control" ref={register()} id="dataFaturamento" placeholder="Data Faturamento" />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Nº Pedido Cliente</label>
                                            <input type="text" name="numeroPedidoCliente" defaultValue={pedido.numeroPedidoCliente} className="form-control" ref={register()} id="numeroPedidoCliente" placeholder="Nº do pedido no cliente" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>Observações</label>
                                            <input type="text" name="observacoes" defaultValue={pedido.observacoes} className="form-control" ref={register()} id="observacoes" placeholder="Observações" />
                                        </div>

                                        <div className="form-group col-md-3">
                                            <label>Forma de Pagamento</label>
                                            <select id="formaDePagamento" name="formaDePagamento" value={pedido.formaDePagamento} ref={register()} onChange={handleInputChangePedido} className="form-control" required>
                                                <option value=""></option>
                                                <option value="1">Dinheiro</option>
                                                <option value="2">Débito em Conta</option>
                                                <option value="3">Cartão de Crédito</option>
                                                <option value="4">Cartão de Débito</option>
                                                <option value="5">Cheque</option>
                                                <option value="6">Boleto</option>
                                                <option value="7">Transferência</option>
                                                <option value="8">PIX</option>
                                            </select>
                                            {errors.parceiroId && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-1">
                                            <label>% Verba</label>
                                            <input type="text" name="percentualVerba" defaultValue={pedido.percentualVerba ? pedido.percentualVerba.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' }) : pedido.percentualVerba} className="form-control" ref={register()} id="percentualVerba" placeholder="% Verba" />
                                        </div>
                                        <div className="form-group col-md-1">
                                            <label>% Desc</label>
                                            <input type="text" name="percentualDesconto" defaultValue={pedido.percentualDesconto ? pedido.percentualDesconto.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' }) : pedido.percentualDesconto} className="form-control" ref={register()} id="percentualDesconto" placeholder="% Desc" />
                                        </div>

                                        <div className="form-group col-md-2">
                                            <label>Parceiro</label>
                                            <select id="parceiroId" name="parceiroId" value={pedido.parceiroId} onChange={handleInputChangePedido} ref={register()} className="form-control">
                                                <option value=""></option>
                                                {
                                                    parceiros && parceiros.map((parceiro, index) => (
                                                        <option key={index} value={parceiro.id}>{parceiro.nome}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.parceiroId && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-1">
                                            <label>% Parceiro</label>
                                            <input type="text" name="percentualParceiro" defaultValue={pedido.percentualParceiro ? pedido.percentualParceiro.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' }) : pedido.percentualParceiro} className="form-control" ref={register()} id="percentualParceiro" placeholder="% Parceiro" />
                                        </div>

                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-7 pt-2">
                                            <div style={{ display: "inline-flex" }}>
                                                <button type="submit" className="btn btn-primary mb-2 mt-1" disabled={pedido.lancamentoId}>Salvar</button>
                                                <Link className="btn btn-danger mx-sm-3 ml-2 mb-2 mt-1" to={`/pedidos/`}>Cancelar</Link>
                                                <a className="btn btn-warning mx-sm-1 ml-1 mb-2 mt-1" target="_blank" rel="noopener noreferrer" href={`/relatorio/pedidoCompleto/${pedido.id}`}><FontAwesomeIcon icon={faPrint} /> Completo</a>
                                                <a className="btn btn-warning mx-sm-1 ml-1 mb-2 mt-1" target="_blank" rel="noopener noreferrer" href={`/relatorio/pedidoResumo/${pedido.id}`}><FontAwesomeIcon icon={faPrint} /> Resumo</a>
                                                {pedido.lancamentoId ? (
                                                    <>
                                                        <a className="btn btn-link mx-sm-1 ml-1 mb-2 mt-1" target="_blank" rel="noopener noreferrer" href={`/Financeiro/lancamento/${pedido.lancamentoId}`}><FontAwesomeIcon icon={faMoneyBillWave} /> Visualizar Lançamento</a>
                                                    </>
                                                ) : (
                                                    pedido.statusPedido === 4 && (
                                                        <>
                                                            <button type="submit" className="btn btn-primary mb-2 mt-1" onClick={gerarCommissao}><FontAwesomeIcon icon={faMoneyBillWave} /> Gerar Lançamento</button>
                                                        </>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-5 pt-2">
                                            <div style={{ textAlign: "right" }}>
                                                <strong>Total Venda: </strong>{pedido.totalVenda} / {pedido.custoLiquidoVenda ? pedido.custoLiquidoVenda.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 0} <br />
                                                <strong> Total Faturado: </strong>{pedido.totalFaturado} / {pedido.custoLiquidoFaturado ? pedido.custoLiquidoFaturado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 0} <br />
                                                <label style={{ fontSize: 12 }}>Quantidade de Itens / Valor Líquido</label>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        pedido.lancamentoId && <div className="alert alert-warning">Edição não permitida, existe um lançamento financeiro vinculado ao pedido!</div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
                <br />
                {
                    pedido.numero &&
                    <div className="card">
                        <div className="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Itens <span className="badge badge-primary">{pedido.pedidoItens.length}</span>
                        </div>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="col-md-9 pt-2">
                                        <div style={{ display: "inline-flex" }}>
                                            <button type="button" className="btn btn-primary mb-2" data-toggle="modal" onClick={popularModelItem} data-target="#exampleModal" disabled={pedido.lancamentoId}>Novo Item</button>
                                            <a className="btn btn-warning mx-sm-3 ml-2 mb-2" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">Grades</a>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pt-2">
                                        <div style={{ display: "inline-flex", float: "right" }}>
                                            <span>Valores Faturados</span>
                                            <Switch className="mx-sm-1 mt-1" onChange={handleInputChangeFaturado} checked={faturado} uncheckedIcon={false} checkedIcon={false} height={20} />
                                        </div>
                                    </div>
                                </div>
                                <form id="formPedidoItem" onSubmit={handleSubmit(onSubmitPedidoItem)}>
                                    <input type="hidden" name={`pedidoItem.id`} id="pedidoItem.id" defaultValue={pedidoItem.id} ref={register()} required />
                                    <input type="hidden" name={`pedidoItem.pedidoId`} id="pedidoItem.pedidoId" defaultValue={pedido.id} ref={register()} required />
                                    <input type="hidden" name={`pedidoItem.produtoId`} id="pedidoItem.produtoId" defaultValue={pedidoItem.produtoId} ref={register()} required />
                                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Item</h5>
                                                    <button type="button" className="close" id="btClose" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-3">
                                                            <label>Referência</label>
                                                            <div className="form-row">
                                                                <div className="form-group col-md-8">
                                                                    <input type="text" name="pedidoItem.produto.referencia" id="pedidoItem.produto.referencia" defaultValue={pedidoItem.produto?.referencia} onKeyPress={keyPressReferencia} onChange={handleInputChangeReferencia} className="form-control" required />
                                                                </div>
                                                                <div className="form-group col-md-2">
                                                                    <button type="button" className="btn btn-light" onClick={localizarProduto}><FontAwesomeIcon className="text-primary" icon={faSearch} /></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-9">
                                                            <label>Descrição</label>
                                                            <input type="text" name={`pedidoItem.produto.descricao`} id="pedidoItem.produto.descricao" defaultValue={pedidoItem.produto?.descricao} className="form-control" placeholder="Selecione um Produto" disabled />
                                                        </div>
                                                    </div>

                                                    <div className="form-row">
                                                        <div className="form-group col-md-3">
                                                            <label>Cor</label>
                                                            <input type="text" name="pedidoItem.cor" id="pedidoItem.cor" defaultValue={pedidoItem.cor} className="form-control" ref={register()} />
                                                        </div>
                                                        <div className="form-group col-md-5">
                                                            <label>Material</label>
                                                            <select id="pedidoItem.materialId" name="pedidoItem.materialId" value={pedidoItem.materialId} onChange={handleInputChangePedidoItem} ref={register()} className="form-control" required>
                                                                <option value=""></option>
                                                                {
                                                                    materiais && materiais.map((material, index) => (
                                                                        <option key={index} value={material.id}>{material.nome}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-md-2">
                                                            <label>% Comissão</label>
                                                            <input type="text" name="pedidoItem.percentual" id="pedidoItem.percentual" defaultValue={pedidoItem.percentual} className="form-control" ref={register()} required />
                                                        </div>
                                                        <div className="form-group col-md-2">
                                                            <label>Custo Líquido</label>
                                                            <input type="text" name="pedidoItem.custoLiquido" id="pedidoItem.custoLiquido" defaultValue={pedidoItem.custoLiquido} className="form-control" ref={register()} required />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="form-row">
                                                        <div className="form-group col-md-1">
                                                            <label>Grades: </label>
                                                            <select id="pedidoItem.tipoGrade" name="pedidoItem.tipoGrade" value={pedidoItem.tipoGrade} onChange={handleInputChangePedidoItem} ref={register()} className="form-control" required>
                                                                <option value=""></option>
                                                                {
                                                                    grades && grades.map((grade, index) => (
                                                                        <option key={index} value={grade.tipo}>{grade.label}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="form-row">
                                                                {pedidoItem.tipoGrade && grades.filter(f => f.tipo === pedidoItem.tipoGrade)[0].valoresGrade.split('|').map((item, index) => (
                                                                    <div key={index} className="form-group col-md">
                                                                        <label style={{ textAlign: "center" }}>{`${item}`}</label>
                                                                        <input type="text" name={`pedidoItem.valoresGradeTemp[${index}].valor`} id={`pedidoItem.valoresGradeTemp[${index}].valor`} className="form-control" ref={register()} />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <h5>Lojas</h5>
                                                    <h6>Vendida: <span id="totalVenda"></span></h6>
                                                    <div className="form-row">
                                                        {lojas && lojas.map((item, index) => (
                                                            <div key={index} className="form-group col-md-1">
                                                                <input type="hidden" name={`pedidoItem.pedidoItemLojas[${index}].lojaId`} ref={register()} defaultValue={item.id} />
                                                                <input type="hidden" name={`pedidoItem.pedidoItemLojas[${index}].pedidoItemId`} ref={register()} defaultValue={pedidoItem.id} />
                                                                <input type="hidden" name={`pedidoItem.pedidoItemLojas[${index}].tipo`} ref={register()} defaultValue="0" />
                                                                <label>{`L${item.numeroFilial}`}</label>
                                                                <input type="text" name={`pedidoItem.pedidoItemLojas[${index}].quantidade`} id={`pedidoItem.pedidoItemLojas[${index}].quantidade`} defaultValue={pedidoItem.pedidoItemLojas?.filter(f => f.lojaId === item.id && f.tipo === 0)[0]?.quantidade} className="form-control" onChange={handleTotal} ref={register()} required />
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <h6>Faturado: <span id="totalFaturado"></span></h6>
                                                    <div className="form-row">
                                                        {lojas && lojas.map((item, index) => (
                                                            <div key={index + lojas.length} className="form-group col-md-1">
                                                                <input type="hidden" name={`pedidoItem.pedidoItemLojas[${index + lojas.length}].lojaId`} ref={register()} defaultValue={item.id} />
                                                                <input type="hidden" name={`pedidoItem.pedidoItemLojas[${index + lojas.length}].pedidoItemId`} ref={register()} defaultValue={pedidoItem.id} />
                                                                <input type="hidden" name={`pedidoItem.pedidoItemLojas[${index + lojas.length}].tipo`} ref={register()} defaultValue="1" />
                                                                <label>{`L${item.numeroFilial}`}</label>
                                                                <input type="text" name={`pedidoItem.pedidoItemLojas[${index + lojas.length}].quantidade`} id={`pedidoItem.pedidoItemLojas[${index + lojas.length}].quantidade`} defaultValue={pedidoItem.pedidoItemLojas?.filter(f => f.lojaId === item.id && f.tipo === 1)[0]?.quantidade} className="form-control" onChange={handleTotal} ref={register()} required />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                                    <button type="submit" className="btn btn-primary">Salvar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="collapse" id="collapseExample">
                                    <div className="card card-body">
                                        <table className="table table-sm table-responsive">
                                            <tbody>
                                                <tr>
                                                    <td className="font-weight-bold">A</td>
                                                    <td>RN</td>
                                                    <td>P</td>
                                                    <td>M</td>
                                                    <td>G</td>
                                                    <td>GG</td>
                                                    <td>G1</td>
                                                    <td>G2</td>
                                                    <td>G3</td>
                                                    <td>G4</td>
                                                    <td>G5</td>
                                                    <td>G6</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold">B</td>
                                                    <td>1</td>
                                                    <td>2</td>
                                                    <td>3</td>
                                                    <td>4</td>
                                                    <td>5</td>
                                                    <td>6</td>
                                                    <td>7</td>
                                                    <td>8</td>
                                                    <td>10</td>
                                                    <td>12</td>
                                                    <td>14</td>
                                                    <td>16</td>
                                                    <td>18</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold">C</td>
                                                    <td>34</td>
                                                    <td>36</td>
                                                    <td>38</td>
                                                    <td>40</td>
                                                    <td>42</td>
                                                    <td>44</td>
                                                    <td>46</td>
                                                    <td>48</td>
                                                    <td>50</td>
                                                    <td>52</td>
                                                    <td>54</td>
                                                    <td>56</td>
                                                    <td>58</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold">D</td>
                                                    <td>16</td>
                                                    <td>17</td>
                                                    <td>18</td>
                                                    <td>19</td>
                                                    <td>20</td>
                                                    <td>21</td>
                                                    <td>22</td>
                                                    <td>23</td>
                                                    <td>24</td>
                                                    <td>25</td>
                                                    <td>26</td>
                                                    <td>27</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold">E</td>
                                                    <td>23</td>
                                                    <td>24</td>
                                                    <td>25</td>
                                                    <td>26</td>
                                                    <td>27</td>
                                                    <td>28</td>
                                                    <td>29</td>
                                                    <td>30</td>
                                                    <td>31</td>
                                                    <td>32</td>
                                                    <td>33</td>
                                                    <td>34</td>
                                                    <td>35</td>
                                                    <td>36</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table style={{ borderCollapse: 'separate', borderSpacing: 0 }} className="table table-sm table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ position: 'sticky', left: 0, backgroundColor: '#efefef', border: '1px solid #dee2e6' }} scope="col">Referência</th>
                                                <th scope="col">Grupo</th>
                                                <th scope="col">Subgrupo</th>
                                                <th scope="col">Material</th>
                                                <th scope="col">Descrição</th>
                                                <th scope="col">Cor</th>
                                                <th scope="col" style={{ textAlign: "center" }} colSpan="2">Grade</th>
                                                <th scope="col">%</th>
                                                <th scope="col">Custo Liq</th>
                                                <th scope="col">Lojas</th>
                                                <th scope="col">Total</th>
                                                <th scope="col">Custo Total</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pedido.pedidoItens && pedido.pedidoItens.map(itens => (
                                                <tr key={itens.id} style={{ fontSize: 12 }}>
                                                    <td style={{ position: 'sticky', left: 0, backgroundColor: '#efefef', border: '1px solid #dee2e6' }}>{itens.produto.referencia}</td>
                                                    <td>{itens.produto.grupo.nome}</td>
                                                    <td>{itens.produto.subGrupo.nome}</td>
                                                    <td>{itens.material.nome}</td>
                                                    <td>{itens.produto.descricao}</td>
                                                    <td>{itens.cor}</td>
                                                    <td style={{ verticalAlign: "middle" }}><b>{itens.tipoGrade}</b></td>
                                                    <td style={{ padding: 0, borderTop: 0, borderBottom: 0 }}>
                                                        <table>
                                                            <thead>
                                                                <tr style={{ backgroundColor: 'transparent' }}>
                                                                    {itens.valoresGrade.split('|').map((g, index) => (
                                                                        g.split('=')[1] !== "" && (
                                                                            <th key={index}>
                                                                                {g.split('=')[0]}
                                                                            </th>
                                                                        )
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style={{ backgroundColor: 'transparent' }}>
                                                                    {itens.valoresGrade.split('|').map((g, index) => (
                                                                        g.split('=')[1] !== "" && (
                                                                            <td key={index}>
                                                                                {g.split('=')[1]}
                                                                            </td>
                                                                        )
                                                                    ))}
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td>{itens.percentual}</td>
                                                    <td>
                                                        {itens.custoLiquido.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                    </td>
                                                    <td style={{ padding: 0, borderTop: 0, borderBottom: 0 }}>
                                                        <table>
                                                            <thead>
                                                                <tr style={{ backgroundColor: 'transparent' }}>
                                                                    {itens.pedidoItemLojas.filter(item => item.tipo === 0).map((pedidoItemLoja, index) => (
                                                                        pedidoItemLoja.quantidade > 0 && (
                                                                            <th key={index}>
                                                                                L{pedidoItemLoja.loja.numeroFilial}
                                                                            </th>
                                                                        )
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style={{ backgroundColor: 'transparent' }}>
                                                                    {!faturado && itens.pedidoItemLojas.filter(l => l.tipo === 0).map((pedidoItemLoja, index) => (
                                                                        pedidoItemLoja.quantidade > 0 && (
                                                                            <td key={index}>
                                                                                {pedidoItemLoja.quantidade}
                                                                            </td>
                                                                        )
                                                                    ))}

                                                                    {faturado && (itens.pedidoItemLojas.filter(l => l.tipo === 1).map((pedidoItemLoja, index) => (
                                                                        pedidoItemLoja.quantidade > 0 && (
                                                                            <td key={index}>
                                                                                {pedidoItemLoja.quantidade}
                                                                            </td>
                                                                        )
                                                                    )))}
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td>{!faturado ? itens.total : itens.totalFaturado}</td>
                                                    <td>
                                                        {(!faturado ? itens.custoTotalLiquido : itens.custoTotalLiquidoFaturado).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                    </td>
                                                    <td style={{ textAlign: "right" }}>
                                                        {!pedido.lancamentoId &&
                                                            <div style={{ display: "inline-flex" }}>
                                                                <Link type="button" className="mx-sm-1 ml-2 mb-2" to="#" data-toggle="modal" onClick={() => editarPedidoItem(itens.id)} data-target="#exampleModal"><FontAwesomeIcon className="text-primary ml-2" icon={faEdit} title="Editar" /></Link>
                                                                <button type="button" className="mx-sm-1 ml-2 mb-2" style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => handleExcluir(itens.id)} title="Excluir" ><FontAwesomeIcon className="text-danger ml-2" icon={faTrashAlt} /></button>
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td style={{ position: 'sticky', left: 0, backgroundColor: '#efefef', border: '1px solid #dee2e6' }}></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{!faturado ? pedido.totalVenda : pedido.totalFaturado}</td>
                                                <td>{(!faturado ? pedido.custoLiquidoVenda : pedido.custoLiquidoFaturado).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div >
        </div >
    );
}

export default NovoPedido;