import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faMoneyCheck, faList } from '@fortawesome/free-solid-svg-icons';
import { parseISO, format } from 'date-fns';
import store from '../../../store';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import api from '../../../services/api';
import Erros from '../../../components/Erro';

function Cheques() {
    const [localizarEmitente, setLocalizarEmitente] = useState([]);
    const [baixaCheque, setBaixaCheque] = useState([]);
    const [cheques, setCheques] = useState([]);
    const { register, handleSubmit, errors } = useForm();
    const [contasCorrente, setContasCorrente] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await api.get('/Cheque/todos').then(response => {
                setCheques(response ? response.data : null);
            });

            await api.get('/ContaCorrente/todos').then(response => {
                setContasCorrente(response ? response.data : null);
            });
        }
        fetchData();
    }, [])

    const handleInputLocalizarEmitente = e => {
        setLocalizarEmitente(e.currentTarget.value);
    }

    const handleLocalizar = async e => {
        e.preventDefault();
        await api.get('/Cheque/emitente/' + encodeURIComponent(localizarEmitente === '' ? '%' : localizarEmitente)).then(response => {
            setCheques(response ? response.data : null);
        });
    }

    const handleExcluir = async id => {
        if (window.confirm('Dejesa realmente excluir?')) {
            await api.delete(`/Cheque?id=${id}`).then(response => {
                if (response) {
                    setCheques(cheques.filter(l => l.id !== id));
                }
            });
        }
    }

    const selectCheque = async id => {
        setBaixaCheque(cheques.find(p => p.id === id));
    }

    const onSubmitBaixa = async data => {

        if (baixaCheque.status == 1) {
            data.chequeId = baixaCheque.id;
            await api.put('/Cheque/baixar', data).then(response => {
                if (response) {
                    const cheque = cheques.find(p => p.id === baixaCheque.id);
                    if (cheque) {
                        cheque.chequeId = data.chequeId;
                        cheque.dataBaixa = data.dataBaixa;
                        cheque.contaCorrenteId = data.contaCorrenteId;
                    }
                    successMenssage('Baixa realizada com sucesso!');
                }
            });
        }
        if (baixaCheque.status == 2) {
            await api.put(`/Cheque/cancelarBaixar/${baixaCheque.id}`).then(response => {
                if (response) {
                    const cheque = cheques.find(p => p.id === baixaCheque.id);
                    if (cheque) {
                        cheque.dataBaixa = '';
                        cheque.contaCorrenteId = '';
                    }
                    successMenssage('Baixa cancelada com sucesso!');
                }
            });
        }

        document.getElementById("btClose").click();
        resetForm();
    };

    const successMenssage = msn => {
        const { dispatch } = store;
        dispatch({
            type: 'ADD_ERRO_MENSAGEM',
            error: {
                tipo: 'alert-success',
                hideMessage: true,
                errorMessage: [{ mensagem: msn }]
            }
        });
    }

    function resetForm() {
        setBaixaCheque({});
        document.getElementById("formBaixa").reset();
    }

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div className="card">
                <div className="card-header">Cheques</div>
                <Erros />
                <div className="card-body">
                    <h5 className="card-title">Localizar</h5>
                    <div>
                        <form onSubmit={handleLocalizar}>
                            <div className="form-row">
                                <div className="col-md-4 pt-2">
                                    <label className="sr-only">Emitente</label>
                                    <input type="text" className="form-control" onChange={handleInputLocalizarEmitente} value={localizarEmitente} id="inputLocalizar" placeholder="Emitente" />
                                </div>
                                <div className="col-md-1 pt-2">
                                    <div style={{ display: "inline-flex" }}>
                                        <button type="submit" className="btn btn-primary mx-sm-0 mb-2">Localizar</button>
                                        <Link className="btn btn-success mx-sm-4 ml-2 mb-2" to="/cheques/novo">Novo</Link>
                                    </div>
                                </div>

                            </div>
                        </form>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Banco</th>
                                        <th scope="col">Número</th>
                                        <th scope="col">Emitente</th>
                                        <th scope="col">Emissão</th>
                                        <th scope="col">Vendimento</th>
                                        <th scope="col">Baixa</th>
                                        <th scope="col">Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cheques && cheques.map(ch => (
                                        <tr key={ch.id}>
                                            <td style={{ width: "70px" }}>{ch.banco}</td>
                                            <td style={{ width: "70px" }}>{ch.numero}</td>
                                            <td style={{ width: "170px" }}>{ch.emitente}</td>
                                            <td style={{ width: "100px" }}>
                                                {
                                                    format(parseISO(ch.dataEmissao), 'dd/MM/yyyy')
                                                }
                                            </td>
                                            <td style={{ width: "100px" }}>
                                                {
                                                    format(parseISO(ch.dataVencimento), 'dd/MM/yyyy')
                                                }
                                            </td>
                                            <td style={{ width: "100px" }}>
                                                {
                                                    ch.dataBaixa && format(parseISO(ch.dataBaixa), 'dd/MM/yyyy')
                                                }
                                            </td>
                                            <td style={{ width: "70px" }}>{ch.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                            <td style={{ textAlign: "right", width: "70px" }}>
                                                <div style={{ display: "inline-flex" }}>
                                                    <button type="button" title='Baixar' className="mx-sm-1 ml-2 mb-2" onClick={() => selectCheque(ch.id)} style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} data-toggle="modal" data-target="#exampleModal">
                                                        <FontAwesomeIcon className="text-primary ml-2" icon={faMoneyCheck} />
                                                    </button>
                                                    <Link className="mx-sm-1 ml-2 mb-2" title='Lançamentos' to={'financeiro/lancamentos/cheque/' + ch.id}><FontAwesomeIcon className="text-primary ml-2" icon={faList} /></Link>
                                                    <Link className="mx-sm-1 ml-2 mb-2" title='Editar' to={'/cheques/editar/' + ch.id}><FontAwesomeIcon className="text-primary ml-2" icon={faEdit} /></Link>
                                                    <button type="button" className="mx-sm-1 ml-2 mb-2" title='Excluir' style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} onClick={() => handleExcluir(ch.id)} ><FontAwesomeIcon className="text-danger ml-2" icon={faTrashAlt} /></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <form id="formBaixa" onSubmit={handleSubmit(onSubmitBaixa)}>
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Baixar Cheque</h5>
                                <button type="button" class="close" id="btClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                {baixaCheque.status == 1 && (
                                    <div className="form-row">
                                        <div className="form-group col-md-7">
                                            <label>Conta Corrente</label>
                                            <select id="contaCorrenteId" name="contaCorrenteId" ref={register()} className="form-control" required>
                                                <option value=""></option>
                                                {
                                                    contasCorrente && contasCorrente.map((contaCorrente, index) => (
                                                        <option key={index} value={contaCorrente.id}>{contaCorrente.nome}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.contaCorrenteId && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-5">
                                            <label>Data Baixa</label>
                                            <input type="date" className="form-control" name="dataBaixa" id="dataBaixa" ref={register()} />
                                            {errors.descricao && <span className="text-danger ml-2">*</span>}
                                        </div>
                                    </div>
                                )}
                                {baixaCheque.status == 2 && (
                                    <div>
                                        <div>
                                            <b>Baixado em:</b> {format(parseISO(baixaCheque.dataBaixa), 'dd/MM/yyyy')}
                                        </div>
                                        <div>
                                            <b>Conta Corrente</b> {contasCorrente.find(p => p.id === baixaCheque.contaCorrenteId).nome}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>

                                {baixaCheque.status == 1 && (
                                    <button type="submit" className="btn btn-primary">Baixar</button>
                                )}
                                {baixaCheque.status == 2 && (
                                    <button type="submit" className="btn btn-primary">Cancelar Baixa</button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Cheques;