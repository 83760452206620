import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import store from '../../../store';
import api from '../../../services/api';
import { useHistory, useParams } from "react-router-dom";
import Erros from '../../../components/Erro';

function Cheque() {
    let history = useHistory();
    let { id } = useParams();
    const { register, handleSubmit, errors } = useForm();
    const [cheque, setCheque] = useState({});

    useEffect(() => {
        async function fetchData() {
            if (id) {
                await api.get(`/Cheque/id/${id}`).then(response => {
                    setCheque(response ? response.data : null);
                });
            }
        }
        fetchData();
    }, [id])

    const successMenssage = () => {
        const { dispatch } = store;
        dispatch({
            type: 'ADD_ERRO_MENSAGEM',
            error: {
                tipo: 'alert-success',
                hideMessage: true,
                errorMessage: [{ mensagem: 'Registro Salvo com sucesso!' }]
            }
        });
    }

    const onSubmit = async data => {
        data.valor = parseFloat(data.valor.replace('.', '').replace(',', '.'));
        if (!cheque.id) {
            await api.post('/Cheque', data).then(response => {
                if (response) {
                    setCheque(response.data);
                    history.push(`/Cheques`)
                    successMenssage();
                }
            });
        } else {
            data.id = cheque.id;
            await api.put('/Cheque', data).then(response => {
                if (response) {
                    successMenssage();
                }
            });
        }
    };

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div id="accordion">
                <div className="card">
                    <div className="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {!cheque.id ? 'Novo Cheque' : `Editar Cheque`}
                    </div>
                    <Erros />
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                            <div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-2">
                                            <label>Banco</label>
                                            <input type="text" className="form-control" name="banco" id="banco" defaultValue={cheque.banco} ref={register({ required: true })} placeholder="Banco" />
                                            {errors.descricao && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-1">
                                            <label>Agência</label>
                                            <input type="text" className="form-control" name="agencia" id="agencia" defaultValue={cheque.agencia} ref={register({ required: true })} placeholder="Agência" />
                                            {errors.descricao && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-1">
                                            <label>Conta</label>
                                            <input type="text" className="form-control" name="conta" id="conta" defaultValue={cheque.conta} ref={register({ required: true })} placeholder="Conta" />
                                            {errors.descricao && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-1">
                                            <label>Número</label>
                                            <input type="text" className="form-control" name="numero" id="numero" defaultValue={cheque.numero} ref={register({ required: true })} placeholder="Número" />
                                            {errors.descricao && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-7">
                                            <label>Emitente</label>
                                            <input type="text" className="form-control" name="emitente" id="emitente" defaultValue={cheque.emitente} ref={register({ required: true })} placeholder="Emitente" />
                                            {errors.descricao && <span className="text-danger ml-2">*</span>}
                                        </div>

                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>CPF/CNPJ</label>
                                            <input type="text" className="form-control" name="cpfcnpj" id="cpfcnpj" defaultValue={cheque.cpfcnpj} ref={register({ required: true })} placeholder="CPFCNPJ" />
                                            {errors.descricao && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label>Telefone</label>
                                            <input type="text" className="form-control" name="telefone" id="telefone" defaultValue={cheque.telefone} ref={register()} placeholder="Telefone" />
                                            {errors.descricao && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label>Emissão</label>
                                            <input type="date" className="form-control" name="dataEmissao" id="dataEmissao" defaultValue={cheque.dataEmissao} ref={register({ required: true })} placeholder="Data de Emissão" />
                                            {errors.descricao && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label>Vencimento</label>
                                            <input type="date" className="form-control" name="dataVencimento" id="dataVencimento" defaultValue={cheque.dataVencimento} ref={register({ required: true })} placeholder="Data de Vencimento" />
                                            {errors.descricao && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label>Valor</label>
                                            <input type="text" className="form-control" name="valor" id="valor" defaultValue={cheque.valor?.toLocaleString('pt-br')} ref={register({ required: true })} placeholder="Valor" />
                                            {errors.descricao && <span className="text-danger ml-2">*</span>}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-2 pt-2">
                                            <div style={{ display: "inline-flex" }}>
                                                <button type="submit" className="btn btn-primary mb-2 mt-1">Salvar</button>
                                                <Link className="btn btn-danger mx-sm-3 ml-2 mb-2 mt-1" to={`/cheques`}>Cancelar</Link>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    );
}

export default Cheque;