import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { parseISO, format } from 'date-fns';
import store from '../../../store';
import api from '../../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faTrashRestoreAlt, faMoneyCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from "react-router-dom";
import Erros from '../../../components/Erro'
import { StatusLacamento, TipoLacamento, MeioPagamento } from "../../../utils/Utils";

function Lancamento() {
    let history = useHistory();
    let { id } = useParams();
    const { register, handleSubmit, errors } = useForm();
    const [lancamento, setLancamento] = useState({});
    const [conta, setConta] = useState({});
    const [clientesFornecedores, setFavorecidos] = useState([]);
    const [centrosCusto, setCentrosCusto] = useState([]);
    const [naturezasFinanceiras, setNaturezasFinanceiras] = useState([]);
    const [contasCorrente, setContasCorrente] = useState([]);
    const [localizarEmitente, setLocalizarEmitente] = useState([]);
    const [cheques, setCheques] = useState([]);
    const [vincularCheque, setVincularCheque] = useState([]);
    const [contaSelecionada, setContaSelecionada] = useState([]);
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const [chequeLocalizarShow, setChequeLocalizarShow] = useState(true);

    useEffect(() => {
        async function fetchData() {
            if (id) {
                await api.get(`/Lancamento/id/${id}`).then(response => {
                    setLancamento(response ? response.data : null);
                });
            }
            await api.get('/ClienteFornecedor/todos').then(response => {
                setFavorecidos(response ? response.data : null);
            });
            await api.get('/CentroCusto/todos').then(response => {
                setCentrosCusto(response ? response.data : null);
            });
            await api.get('/NaturezaFinanceira/todos').then(response => {
                setNaturezasFinanceiras(response ? response.data : null);
            });
            await api.get('/ContaCorrente/todos').then(response => {
                setContasCorrente(response ? response.data : null);
            });
        }
        fetchData();
    }, [id])

    const handleLocalizar = async e => {
        e.preventDefault();
        await api.get('/Cheque/conta/' + contaSelecionada + '/' + encodeURIComponent(localizarEmitente === '' ? '%' : localizarEmitente)).then(response => {
            setCheques(response ? response.data : null);
        });
    }

    const obterVinculadoPorConta = async id => {
        await api.get('/Cheque/vinculadoPorConta/' + id).then(response => {
            if (response.data == '') {
                setChequeLocalizarShow(true);
                return;
            }
            setCheques(response ? [response.data] : null);
            setChequeLocalizarShow(false)
        });
    }

    const handleInputLocalizarEmitente = e => {
        setLocalizarEmitente(e.currentTarget.value);
    }

    const selectCheque = async id => {
        setVincularCheque(id);
        setButtonDisabled(false);
    }

    const onSubmitVinculoCheque = async () => {
        await api.put(`/Cheque/IncluirVinculoConta/${vincularCheque}/${contaSelecionada}`).then(response => {
            if (response) {
                document.getElementById("btCloseModalVinculoCheque").click();
                successMenssage('Registro Salvo com sucesso!');
            }
        });
    }

    const onSubmitExcluirVinculoConta = async idCheque => {
        await api.delete(`/Cheque/ExcluirVinculoConta/${idCheque}/${contaSelecionada}`).then(response => {
            if (response) {
                document.getElementById("btCloseModalVinculoCheque").click();   
                successMenssage('Vinculo removido com sucesso!!');             
            }
        });
    }

    const selectConta = async id => {
        setButtonDisabled(true);
        setVincularCheque(null);
        setCheques([]);
        setContaSelecionada(id);
        obterVinculadoPorConta(id);
    }

    const successMenssage = msg => {
        const { dispatch } = store;
        dispatch({
            type: 'ADD_ERRO_MENSAGEM',
            error: {
                tipo: 'alert-success',
                hideMessage: true,
                errorMessage: [{ mensagem: msg }]
            }
        });
    }
    

    const vincularPedido = async e => {
        e.preventDefault();
        var numero = document.getElementById("numeroPedido").value;
        if (numero) {
            await api.put(`/Lancamento/vincularPedido/${lancamento.id}/${numero}`).then(response => {
                if (response) {
                    setLancamento(response.data);
                    history.push(`/financeiro/lancamento/${response.data.id}`)
                    successMenssage('Registro Salvo com sucesso!');
                }
            });
        }
    }

    const removerVincularPedido = async e => {
        e.preventDefault();
        await api.put(`/Lancamento/removerVincularPedido/${lancamento.id}`).then(response => {
            if (response) {
                setLancamento(response.data);
                history.push(`/financeiro/lancamento/${response.data.id}`)
                successMenssage('Vinculo removido com sucesso!');
            }
        });
    }

    const onSubmitLancamento = async data => {
        if (!lancamento.id) {
            await api.post('/Lancamento/lancamento', data).then(response => {
                if (response) {
                    setLancamento(response.data);
                    history.push(`/financeiro/lancamento/${response.data.id}`)
                    successMenssage('Registro Salvo com sucesso!');
                }
            });
        } else {
            data.id = lancamento.id;
            await api.put('/Lancamento/lancamento', data).then(response => {
                if (response) {
                    setLancamento(response.data);
                    successMenssage('Registro Salvo com sucesso!');
                }
            });
        }
    };

    function handleInputChangeLancamento(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setLancamento(prev => ({ ...prev, [name]: value }));
    }

    const onSubmitConta = async data => {
        data.conta.meioPagamento = parseInt(data.conta.meioPagamento.replace(',', '.'));
        data.conta.valor = parseFloat(data.conta.valor.replace('.', '').replace(',', '.'));
        data.conta.valorBaixado = data.conta.valorBaixado && parseFloat(data.conta.valorBaixado.replace('.', '').replace(',', '.'));
        data.conta.dataBaixa = data.conta.dataBaixa === "" ? undefined : data.conta.dataBaixa;
        data.conta.valorBaixado = data.conta.valorBaixado === "" ? undefined : data.conta.valorBaixado;

        if (!data.conta.id) {
            data.conta.id = undefined;
            await api.post('/Lancamento/conta', data.conta).then(response => {
                if (response) {
                    setLancamento(response.data);
                }
            });
        } else {
            await api.put('/Lancamento/conta', data.conta).then(response => {
                if (response) {
                    setLancamento(response.data);
                }
            });
        }
        resetForm();
        document.getElementById("btClose").click();
    }

    const handleExcluir = async id => {
        if (window.confirm('Dejesa realmente excluir?')) {
            await api.delete(`/Lancamento/conta/id/${id}`).then(response => {
                if (response) {
                    setLancamento(response.data);
                }
            });
        }
    }

    const editarConta = async data => {
        if (lancamento.contas) {
            setConta(lancamento.contas.find(c => c.id === data));
        }
    }

    function handleInputChangeMeioPagamento(event) {
        const target = event.target;
        setConta(prev => ({ ...prev, "meioPagamento": target.value }));
    }

    function resetForm() {
        setConta({});
        document.getElementById("formConta").reset();
    }

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div id="accordion">
                <div className="card">
                    <div className="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {!lancamento.id ? 'Novo Lançamento' : `Editar Lançamento`}
                        <TipoLacamento data={lancamento.tipo} /> <StatusLacamento data={lancamento.status} />
                    </div>
                    <Erros />
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                            <div>
                                <form onSubmit={handleSubmit(onSubmitLancamento)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>Cliente/Fornecedor</label>
                                            <select id="clienteFornecedorId" name="clienteFornecedorId" value={lancamento.clienteFornecedorId} onChange={handleInputChangeLancamento} ref={register()} className="form-control" required>
                                                <option value=""></option>
                                                {
                                                    clientesFornecedores && clientesFornecedores.map((clienteFornecedor, index) => (
                                                        <option key={index} value={clienteFornecedor.id}>{clienteFornecedor.nome}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.clienteFornecedorId && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Centro de Custo</label>
                                            <select id="centroCustoId" name="centroCustoId" value={lancamento.centroCustoId} onChange={handleInputChangeLancamento} ref={register()} className="form-control" required>
                                                <option value=""></option>
                                                {
                                                    centrosCusto && centrosCusto.map((centroCusto, index) => (
                                                        <option key={index} value={centroCusto.id}>{centroCusto.nome}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.centroCustoId && <span className="text-danger ml-2">*</span>}
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Natureza Financeira</label>
                                            <select id="naturezaFinanceiraId" name="naturezaFinanceiraId" value={lancamento.naturezaFinanceiraId} onChange={handleInputChangeLancamento} ref={register()} className="form-control" required>
                                                <option value=""></option>
                                                {
                                                    naturezasFinanceiras && naturezasFinanceiras.map((naturezaFinanceira, index) => (
                                                        <option key={index} value={naturezaFinanceira.id}>{naturezaFinanceira.descricao}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.naturezaFinanceiraId && <span className="text-danger ml-2">*</span>}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-2">
                                            <label>Data de Lançamento</label>
                                            <input type="date" name="dataLancamento" defaultValue={lancamento.dataLancamento} className="form-control" ref={register()} id="dataLancamento" placeholder="Data de Lançamento" required />
                                            {errors.dataLancamento && <span className="text-danger ml-2">*</span>}
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>Conta Corrente</label>
                                            <select id="contaCorrenteId" name="contaCorrenteId" value={lancamento.contaCorrenteId} onChange={handleInputChangeLancamento} ref={register()} className="form-control" required>
                                                <option value=""></option>
                                                {
                                                    contasCorrente && contasCorrente.map((contaCorrente, index) => (
                                                        <option key={index} value={contaCorrente.id}>{contaCorrente.nome}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.contaCorrenteId && <span className="text-danger ml-2">*</span>}
                                        </div>


                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md">
                                            <label>Descrição</label>
                                            <textarea name="descricao" defaultValue={lancamento.descricao} className="form-control" ref={register()} id="descricao" placeholder="Descrição" required />
                                            {errors.descricao && <span className="text-danger ml-2">*</span>}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-2 pt-2">
                                            <div style={{ display: "inline-flex" }}>
                                                <button type="submit" className="btn btn-primary mb-2 mt-1">Salvar</button>
                                                <Link className="btn btn-danger mx-sm-3 ml-2 mb-2 mt-1" to={`/financeiro/lancamentos`}>Cancelar</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-10 pt-2">
                                            <div style={{ textAlign: "right", marginTop: "10px" }}>
                                                {lancamento.pedido ? (
                                                    <>
                                                        <b>Pedido Vinculado: </b>
                                                        <a href={`/pedidos/${lancamento.pedido.id}`}>
                                                            {`${lancamento.pedido.numero} - ${lancamento.pedido.cliente.nome} / ${lancamento.pedido.fornecedor.razaoSocial}`}
                                                        </a>
                                                        <button type="button" className="mx-sm-1 ml-2 mb-2" style={{ border: 'none', backgroundColor: 'transparent' }} onClick={removerVincularPedido} title="Excluir" ><FontAwesomeIcon className="text-danger ml-2" icon={faTrashRestoreAlt} /></button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div style={{ display: "inline-flex", marginTop: "-6px" }}>
                                                            <div className="form-group mb-2">
                                                                <label className="form-control-plaintext">Vincular Pedido</label>
                                                            </div>
                                                            <div className="form-group mx-sm-3 mb-2">
                                                                <label className="sr-only">Número do Pedido</label>
                                                                <input type="text" className="form-control" id="numeroPedido" placeholder="Número do Pedido" />
                                                            </div>
                                                            <button type="submit" onClick={vincularPedido} className="btn btn-primary mb-2">Vincular</button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <br />
                    {
                        lancamento.id &&
                        <div className="card">
                            <div className="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Itens <span className="badge badge-primary">{lancamento.contas.length}</span>
                            </div>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                <div className="card-body">
                                    <div className="form-row">
                                        <div className="col-md-9 pt-2">
                                            <div style={{ display: "inline-flex" }}>
                                                <button type="button" className="btn btn-primary mb-2" data-toggle="modal" onClick={resetForm} data-target="#exampleModal">Nova Conta</button>
                                            </div>
                                        </div>
                                    </div>
                                    <form id="formConta" onSubmit={handleSubmit(onSubmitConta)}>
                                        <input type="hidden" name="conta.id" id="conta.id" defaultValue={conta.id} ref={register()} required />
                                        <input type="hidden" name="conta.lancamentoId" id="conta.lancamentoId" defaultValue={lancamento.id} ref={register()} required />
                                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">Conta</h5>
                                                        <button type="button" className="close" id="btClose" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="form-row">
                                                            <div className="form-group col-md-3">
                                                                <label>Vencimento</label>
                                                                <input type="date" name="conta.dataVencimento" id="conta.dataVencimento" defaultValue={conta.dataVencimento} className="form-control" ref={register()} placeholder="Vencimento" required />
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label>Valor</label>
                                                                <input type="text" name="conta.valor" id="conta.valor" defaultValue={conta.valor?.toLocaleString('pt-br')} className="form-control" ref={register()} placeholder="Valor" required />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label>Meio de Pagamento</label>
                                                                <select id="conta.meioPagamento" name="conta.meioPagamento" value={conta.meioPagamento} ref={register()} onChange={handleInputChangeMeioPagamento} className="form-control" required>
                                                                    <option value=""></option>
                                                                    <option value="1">Dinheiro</option>
                                                                    <option value="2">Débito em Conta</option>
                                                                    <option value="3">Cartão de Crédito</option>
                                                                    <option value="4">Cartão de Débito</option>
                                                                    <option value="5">Cheque</option>
                                                                    <option value="6">Boleto</option>
                                                                    <option value="7">Transferência</option>
                                                                    <option value="8">PIX</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-3">
                                                                <label>Baixa</label>
                                                                <input type="date" name="conta.dataBaixa" id="conta.dataBaixa" defaultValue={conta.dataBaixa} className="form-control" ref={register()} placeholder="Baixa" />
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label>Valor Baixado</label>
                                                                <input type="text" name="conta.valorBaixado" id="conta.valorBaixado" defaultValue={conta.valorBaixado?.toLocaleString('pt-br')} className="form-control" ref={register()} placeholder="Valor Baixado" />
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label>Utilizar Saldo</label>
                                                                <input type="checkbox" name="conta.utilizarSaldo" id="conta.utilizarSaldo" className="form-control" ref={register()} placeholder="Utilizar Saldo" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                                        <button type="submit" className="btn btn-primary">Salvar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="table-responsive">
                                        <table className="table table-sm table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Vencimento</th>
                                                    <th scope="col">Valor</th>
                                                    <th scope="col">Meio de Pagamento</th>
                                                    <th scope="col">Baixa</th>
                                                    <th scope="col">Valor Baixado</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {lancamento.contas && lancamento.contas.map(conta => (
                                                    <tr key={conta.id}>
                                                        <td>{format(parseISO(conta.dataVencimento), 'dd/MM/yyyy')}</td>
                                                        <td>{conta.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                                        <td><MeioPagamento meio={conta.meioPagamento} /></td>
                                                        <td>{conta.dataBaixa && format(parseISO(conta.dataBaixa), 'dd/MM/yyyy')}</td>
                                                        <td>{conta.valorBaixado?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                                        <td style={{ textAlign: "right" }}>
                                                            <div style={{ display: "inline-flex" }}>
                                                                <button type="button" title='Baixar' className="mx-sm-1 ml-2 mb-2" onClick={() => selectConta(conta.id)} style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} data-toggle="modal" data-target="#modalVinculoCheque">
                                                                    <FontAwesomeIcon className="text-primary ml-2" icon={faMoneyCheck} />
                                                                </button>



                                                                <Link type="button" className="mx-sm-1 ml-2 mb-2" to="#" data-toggle="modal" onClick={() => editarConta(conta.id)} data-target="#exampleModal"><FontAwesomeIcon className="text-primary ml-2" icon={faEdit} title="Editar" /></Link>
                                                                <button type="button" className="mx-sm-1 ml-2 mb-2" style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => handleExcluir(conta.id)} title="Excluir" ><FontAwesomeIcon className="text-danger ml-2" icon={faTrashAlt} /></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td></td>
                                                    <td>{lancamento.valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{lancamento.valorTotalBaixado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <div className="modal fade" id="modalVinculoCheque" tabIndex="-1" aria-labelledby="modalVinculoCheque" aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="modalVinculoCheque">Vínculo Cheque</h5>
                                                        <button type="button" className="close" id="btCloseModalVinculoCheque" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="card-body">
                                                            <h5 className="card-title">Localizar</h5>
                                                            <div>
                                                                {chequeLocalizarShow &&
                                                                    <form onSubmit={handleLocalizar}>
                                                                        <div className="form-row">
                                                                            <div className="col-md-4 pt-2">
                                                                                <label className="sr-only">Emitente</label>
                                                                                <input type="text" className="form-control" onChange={handleInputLocalizarEmitente} value={localizarEmitente} id="inputLocalizar" placeholder="Emitente" />
                                                                            </div>
                                                                            <div className="col-md-1 pt-2">
                                                                                <div style={{ display: "inline-flex" }}>
                                                                                    <button type="submit" className="btn btn-primary mx-sm-0 mb-2">Localizar</button>
                                                                                    <Link className="btn btn-success mx-sm-4 ml-2 mb-2" to="/cheques/novo">Novo</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                }
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Banco</th>
                                                                                <th scope="col">Número</th>
                                                                                <th scope="col">Emitente</th>
                                                                                <th scope="col">Vendimento</th>
                                                                                <th scope="col">Valor</th>
                                                                                <th scope="col"></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {cheques && cheques.map(ch => (
                                                                                <tr key={ch.id}>
                                                                                    <td>{ch.banco}</td>
                                                                                    <td>{ch.numero}</td>
                                                                                    <td>{ch.emitente}</td>
                                                                                    <td>
                                                                                        {
                                                                                            format(parseISO(ch.dataVencimento), 'dd/MM/yyyy')
                                                                                        }
                                                                                    </td>
                                                                                    <td>{ch.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                                                                    <td>
                                                                                        {chequeLocalizarShow &&
                                                                                            <input className="form-check-input" type="radio" name="radioCheque" onClick={() => selectCheque(ch.id)} id="radioCheque" value="option1"></input>
                                                                                        }
                                                                                        {!chequeLocalizarShow &&
                                                                                            <button type="button" title='Baixar' className="mx-sm-1 ml-2 mb-2" onClick={() => onSubmitExcluirVinculoConta(ch.id)} style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} data-toggle="modal" data-target="#modalVinculoCheque">
                                                                                                <FontAwesomeIcon className="ml-2"  style={{color: "#ff0000"}} icon={faTimesCircle} />
                                                                                            </button>
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                                        <button type="submit" className="btn btn-primary" onClick={() => onSubmitVinculoCheque()} disabled={isButtonDisabled}>Vincular</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div >
                <br />
            </div >
        </div >
    );
}

export default Lancamento;